<template>
  <div class="is-flex is-align-items-center">
    <template v-if="loaded">
      <template v-if="!!imageUrl">
        <img :src="imageUrl" alt="" class="partner-logo" />
      </template>
      <p
        v-if="!hideName"
        class="is-size-5 has-text-weight-medium is-hidden-touch"
      >
        {{ partnerName }}
      </p>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PartnerLogo",
  props: {
    offerId: {
      type: Number,
      required: false,
    },
    partnerNameProp: {
      type: String,
      required: false,
    },
    partnerLogoProp: {
      type: String,
      required: false,
    },
    hideName: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  async created() {
    this.mutable = !!this.offerId; // mutability never changes after creation

    await this.downloadImage();

    this.loaded = true;
  },
  data() {
    return {
      mutable: true, // 99% of the time
      loaded: false,
    };
  },
  watch: {
    async partnerLogo() {
      await this.downloadImage();
    },
  },
  computed: {
    imageDetails() {
      let details = this.getImageDetailsForOffer(this.offerId);
      return details ? details : {};
    },
    partnerName() {
      if (this.mutable) {
        return this.imageDetails.partner;
      } else {
        return this.partnerNameProp;
      }
    },
    partnerLogo() {
      if (this.mutable) {
        return this.imageDetails.logo;
      } else {
        return this.partnerLogoProp;
      }
    },
    imageUrl() {
      // this should work even when offerId changes because at one point all logos MUST get loaded in created(),
      // we don't need to await the download when it changes because it should be cached
      if (this.partnerLogo) {
        let blob = this.fileGetter(this.partnerLogo);
        if (blob) {
          try {
            return URL.createObjectURL(blob);
          } catch (e) {
            console.warn("Couldn't resolve logo blob!");
          }
        }
      }
      return null;
    },
    ...mapGetters({
      fileGetter: "drawer/getFile",
      getImageDetailsForOffer: "image/getImageDetailsForOffer",
    }),
  },
  methods: {
    ...mapActions({
      fetchFile: "drawer/downloadPublic",
    }),
    async downloadImage() {
      if (!!this.partnerLogo && !this.fileGetter(this.partnerLogo)) {
        try {
          await this.fetchFile(this.partnerLogo);
        } catch (e) {
          console.warn("Couldn't fetch Bank logo!");
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.partner-logo {
  height: 30px;
  margin-right: 20px;
}

.mbh {
  .partner-logo {
    max-height: 16px;
  }
}
</style>
